a {
  text-decoration: none;
  color: $primary;

  &:hover {
    text-decoration: underline;
  }

  &:hover, &:visited, &:active {
    color: $primary;
  }
}

.button-panel {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
}

@mixin button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;

  // Ensure sole buttons are placed on the right
  &:last-child {
    margin-left: auto;
  }
}

.off-button {
  @include button;
  color: $gray;
  box-shadow: none;
  background-color: $primary-off;
}

.next-button {
  @include button;
  color: $white;
  background-color: $gradient-start;
  background: linear-gradient(to top, $gradient-start, $gradient-stop-1);
}

a.next-button, a.back-button {
  &:focus, &:visited, &:active {
    color: $white;
  }

  &:hover {
    text-decoration: none;
  }
}
