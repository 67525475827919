// Override default layout using a one-column layout always
body {
  .welcome {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "main"
      "picture";
    color: $white;
    background: $black-1;

    @include for-phone-only {
      margin-top: 1em;
    }

    &[id=picture] {
      grid-area: picture;
      width: 100%;
    }

    main {
      grid-area: main;
      padding: 10em 5em;

      h1 {
        color: $white;
        display: block; // reset
        padding: 1rem 0;
        font-family: $font-family-bold;

        @include for-phone-only {
          text-align: center;
          margin: 0;
          padding: 1rem 0;
        }
      }

      h2 {
        text-transform: uppercase;
        font-size: 1.2rem;
        padding: 1rem 0;
        margin: 0;
        strong {
          color: $font-color-strong;
          font-style: normal;
          font-weight: bold;
        }
      }

      p {
        padding: 1rem 0;
      }

      #sms {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1rem;
        padding: 0 1rem;

        p {
          font-weight: bold;
          padding: 0;
        }

        border: 1px solid $primary;
      }

      a.next-button {
        text-align: center;
        vertical-align: center;
        font-weight: bold;
        font-size: 1em;
        box-sizing: border-box;
        display: block;
        padding: 0.8em 0;
        height: 3em;
        border-radius: 2em;
      }

      @include for-phone-only {
        padding: 1em 2em;
      }
    }

    @include for-phone-only {
      grid-template-columns: 1fr;
      grid-template-areas:
        "picture"
        "main";
    }
  }
}

.welcome-footer {
  grid-area: footer;
  display: flex;
  justify-content: flex-end;

  .next-button:last-child {
    margin-left: 1em;
  }
}
