.evo-hp {
  // Typography
  @import url(//fonts.googleapis.com/css?family=Roboto:100,200,400,700,900);
  $font-size-default: 16px;
  $font-family-default: 'Roboto', sans-serif;
  $font-family-bold: 'Roboto', sans-serif;
  $font-family-credit-card: $font-family-default;

  // Theme colors
  $white: #fff;
  $black-1: #2b2b2b;
  $primary: #01b2aa;
  $dropshadow: rgba(1, 178, 170, 0.3);
  $primary-off: #036662;

  // Gradiants
  $gradient-start: $primary;
  $gradient-stop-1: $primary;
  $gradient-stop-2: $primary;

  // Keep for now
  $font-color-strong: $primary;

  // Apply theme to shared styles
  @import '../../shared/index.scss';
}
