body {
  background: $white;
  height: 100%;

  #root {
    height: 100%;
  }

  // Centered container holding header + content
  .container {
    margin-left: auto;
    margin-right: auto;
    height: 100%;

    // Wraps logo + potentially a locale switcher
    //
    // Seperated from content to allow EVO HP logo
    // use less padding than the main content
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2em 0 1em 0;
      @include for-phone-only {
        padding: 1.5em 0 0 0;
      }

      header.logo {
        width: 5rem;
        height: auto;
      }

      // Positioning of locale switcher is adapted to EVO HP logo
      position: relative;
      .locale-switcher {
        position: absolute;
        top: 30%;
        right: 2em;
        .locale {
          display: inline-block;
          margin-left: 0.5em;

          &.active {
            font-weight: bold;
          }
        }
      }
    }

    // Page title
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      text-transform: uppercase;
      padding: 0 0 0.7em 0;
      font-family: $font-family-bold;
    }

    // Used for payment declined only
    .paymentError {
      background: red;
      color: $white;
      display: flex;
      justify-content: center;
      padding: 1em 0;
    }

    // Signup form
    .signup-form-container {
      background: $black-1;
      display: flex;
      justify-content: center;

      form {
        max-width: 35rem;
        padding: 1em 5em;

        @include for-phone-only {
          color: $white;
          grid-template-columns: 1fr;
          padding: 1em;
        }

        .form-group {
          margin-bottom: 1em;
        }
      }
    }

    // Only processing page
    .content {
      &.processing, &.error {
        display: flex;
        flex-direction: column;
        padding: 5em 0 0 0;
        text-align: center;
        img {
          margin: 1em;
        }
      }

      .main {
        grid-area: main;
      }
    }
  }
}

p {
  margin: 1em 0;
}

span.as-link {
  color: $primary;
  cursor: pointer;
}
