form {
  .form-group {
    color: $white;
    border: 1px solid $black-1;
    border-radius: .2em;
    background-color: $black-1;
    padding: 1.5em 1em;
    @include for-phone-only {
      padding: 1em 1em;
    }

    .errorText {
      font-size: 0.8em;
      color: $error;
      padding: 0 0.5em 1em 0.5em;
    }

    h2 {
      color: $white;
      font-size: 1.5em;
      font-weight: bold;
      padding-bottom: 0.25em;
    }

    h3 {
      color: $primary;
      font-size: 1.1em;
      font-weight: bold;
      padding-bottom: 0.25em;
      text-align: center;
    }

    label {
      &[for="optIn"], &[for="optOut"] {
        display: flex;
        padding-bottom: 1em;
        gap: 0.5em;
      }
    }

    input {
      font-size: 1em;
      color: $black-1;
      font-family: $font-family-default;
      margin: 0.5em 0;
      padding: 1em 0.5em;
      line-height: 1.5em;
      border: 2px solid $black-1;
      border-radius: 0.5em;
      box-sizing: border-box;

      &[type="text"], &[type="email"], &[type="password"] {
        width: 100%;

        // Make postal code shorter to indicate shorter value
        &.code {
          width: 50%;
        }
      }

      &[type="radio"] {
        appearance: none;
        background-color: none;
        margin: 0 .3em 0 0;
        font: inherit;
        color: currentColor;
        width: 1.15em;
        height: 1.15em;
        border: 2px solid currentColor;
        border-radius: 50%;
        transform: translateY(0.2em);
        padding: 0.5em;

        &:checked {
          background: $primary; // must be non-white
        }
      }

      &[type="checkbox"] {
        padding: 0.5em;
        background-color: red;

        &:checked {
          background: $primary; // must be non-white
        }
      }

      &:focus {
        outline: none;
        border: 2px solid $primary;
        font-weight: bold;
      }

      &.error {
        border: 1px solid $error;
      }
    }

    // Align horizontally on larger screens
    .gender, .payment-method {
      display: flex;
      @include for-phone-only {
        display: block;
      }

      label {
        padding-right: 0.5em;
      }
    }

    .gender {
      .errorText {
        padding-top: 0.7em;
      }
    }

    .birthdate {
      margin-bottom: 1em;
      label {
        display: block;
        padding: 0.1em 0;
      }
    }

    .mobile {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    select {
      font-family: $font-family-default;
      color: $black-1;
      font-size: 1em;
      height: 3.6em;
      border: 2px solid $black-1;
      border-radius: 0.5em;
      padding: 0.5em;
      box-sizing: border-box;
      margin-bottom: 0.3em;

      &:focus {
        outline: none;
        border: 2px solid $primary;
      }

      &.locationSelect, &.regionSelect {
        width: 100%;
      }

      &.mobilePrefixSelect {
        margin-right: 0.5em;
      }

      // Any of day/month/year
      &.birthdateSelect {
        margin-right: 0.5em;
      }

      &.error {
        border: 1px solid $error;
      }
    }

    button {
      &.text-button {
        background-color: $black-1;
        color: $primary;
        border: none;
        font-size: 1em;
        font-weight: bold;
      }
      &.small {
        font-size: 0.9em;
        font-weight: normal;
      }
      &.disabled {
        color: $white
      }
      &:hover {
        cursor: pointer;
      }
    }

    div {
      &.subnote {
        text-align: right;
        position: relative;
        top: -0.8em;
      }
    }
  }

  .form-group#verify-resend {
    padding: 1em 0.5em;
    text-align: center;
    border: none;
  }

  footer {
    padding: 0 1em;
    color: $white;

    label {
      &[for="acceptTerms"], &[for="optOut"] {
        display: flex;
        padding-bottom: 0.4em;
        gap: 0.5em;
      }
    }

    input[type="checkbox"] {
      appearance: none;
      background-color: transparent;
      margin: 0 .1em 0 0;
      font: inherit;
      color: white;
      width: 0.75em;
      height: 0.25em;
      border: 1px solid currentColor;
      padding: 0.4em;
      position: relative; 
    }

    input[type="checkbox"]:checked {
      background-color: $primary;
      color: white;
    }

    input[type="checkbox"]:checked::before {
      content: '';
      position: absolute;
      left: 0.4em;
      top: 0;
      width: 0.2em;
      height: 0.55em;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: translate(0%, 15%) rotate(45deg);
    }

    .errorText {
      font-size: 0.8em;
      color: $error;
      padding: 0 0.5em 1em 0.5em;
    }
    
    input[type=submit]#checkout-button {
      border-radius: 0.4em;
      font-size: 1em;
      text-transform: uppercase;
      line-height: 1.2em;
      height: 3em;
      margin: 1em 0;

      .next-button {
        color: $white;
        box-shadow: 0px 10px 20px $dropshadow;
        background: linear-gradient(127.18deg, $gradient-start 0%, $gradient-stop-1 101.98%);
      }

      .off-button {
        color: $gray !important;
        box-shadow: none;
        transform: none;
      }
    }

    input[type=submit]#checkout-button.next-button:active {
      box-shadow: 0px 4px 20px $dropshadow;
      transform: translateY(6px);
    }
  }
}
