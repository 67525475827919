// Ensure expected variables are intialized...
$font-size-default: 16px !default;
$font-family-default: sans-serif !default;
$font-family-bold: sans-serif !default;
$font-family-credit-card: sans-serif !default;

$white: white !default;
$black-1: black !default;
$primary: blue !default;
$error: red !default;
$dropshadow: red !default;
$gray: gray !default;
$primary-off: darkblue !default;

$gradient-start: lightgrey !default;
$gradient-stop-1: darkgrey !default;
$gradient-stop-2: black !default;

// Keep for now
$font-color-strong: red !default;

// ...then import shared styles
@import './partials/_reset';
@import './partials/_breakpoints.scss';
@import 'partials/_typography';
@import 'partials/_layout';
@import 'partials/_form';
@import 'partials/_navigation';
@import 'partials/_quote';
@import 'partials/_welcome';
@import 'partials/_credit_card';
