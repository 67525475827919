@font-face {
  font-family: 'AvantGardeITC';
  src: url(./styles/themes/evo-norway/fonts/296048_1_0.woff) format('woff');
}

@font-face {
  font-family: 'AvantGardeITCBold';
  src: url(./styles/themes/evo-norway/fonts/bold.woff) format('woff');
}

.evo-norway {
  // Typography
  @import url(//fonts.googleapis.com/css?family=Open+Sans:100,200,400,700,900);
  $font-size-default: 16px;
  $font-family-default: "AvantGardeITC", "MS Trebuchet", sans-serif;
  $font-family-bold: "AvantGardeITCBold";
  $font-family-credit-card: "Open Sans", sans-serif;

  // Theme colors
  $white: #fff;
  $black-1: #2b2b2b;
  $primary: #d8005b;
  $error: red;
  $dropshadow: rgba(216, 0, 91, 0.3);
  $primary-off: #81093c;

  // Gradiants
  $gradient-start: $primary;
  $gradient-stop-1: $primary;
  $gradient-stop-2: $primary;

  // Keep for now
  $font-color-strong: $primary;

  // Apply theme to shared styles
  @import '../../shared/index.scss';
}
