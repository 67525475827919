@font-face {
  font-family: "AvantGardeITC";
  src: url(./styles/themes/evo-norway/fonts/296048_1_0.woff) format("woff");
}
@font-face {
  font-family: "AvantGardeITCBold";
  src: url(./styles/themes/evo-norway/fonts/bold.woff) format("woff");
}
.evo-norway {
  @import url(//fonts.googleapis.com/css?family=Open+Sans:100,200,400,700,900);
  /*
   * Credit card form
   *
   * Note: cannot use flex layout because of Verifone iframe resizer (nothing displayed)
   */
}
.evo-norway html {
  box-sizing: border-box;
  font-size: 16px;
}
.evo-norway *, .evo-norway *:before, .evo-norway *:after {
  box-sizing: inherit;
}
.evo-norway body, .evo-norway h1, .evo-norway h2, .evo-norway h3, .evo-norway h4, .evo-norway h5, .evo-norway h6, .evo-norway p, .evo-norway ol, .evo-norway ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.evo-norway ol, .evo-norway ul {
  list-style: none;
}
.evo-norway img {
  max-width: 100%;
  height: auto;
}
.evo-norway body {
  color: #2b2b2b;
  font-size: 16px;
  font-family: "AvantGardeITC", "MS Trebuchet", sans-serif;
  line-height: normal;
  font-weight: normal;
}
.evo-norway body {
  background: #fff;
  height: 100%;
}
.evo-norway body #root {
  height: 100%;
}
.evo-norway body .container {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.evo-norway body .container .header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0 1em 0;
  position: relative;
}
@media (max-width: 800px) {
  .evo-norway body .container .header {
    padding: 1.5em 0 0 0;
  }
}
.evo-norway body .container .header header.logo {
  width: 5rem;
  height: auto;
}
.evo-norway body .container .header .locale-switcher {
  position: absolute;
  top: 30%;
  right: 2em;
}
.evo-norway body .container .header .locale-switcher .locale {
  display: inline-block;
  margin-left: 0.5em;
}
.evo-norway body .container .header .locale-switcher .locale.active {
  font-weight: bold;
}
.evo-norway body .container h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 0 0 0.7em 0;
  font-family: "AvantGardeITCBold";
}
.evo-norway body .container .paymentError {
  background: red;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1em 0;
}
.evo-norway body .container .signup-form-container {
  background: #2b2b2b;
  display: flex;
  justify-content: center;
}
.evo-norway body .container .signup-form-container form {
  max-width: 35rem;
  padding: 1em 5em;
}
@media (max-width: 800px) {
  .evo-norway body .container .signup-form-container form {
    color: #fff;
    grid-template-columns: 1fr;
    padding: 1em;
  }
}
.evo-norway body .container .signup-form-container form .form-group {
  margin-bottom: 1em;
}
.evo-norway body .container .content.processing, .evo-norway body .container .content.error {
  display: flex;
  flex-direction: column;
  padding: 5em 0 0 0;
  text-align: center;
}
.evo-norway body .container .content.processing img, .evo-norway body .container .content.error img {
  margin: 1em;
}
.evo-norway body .container .content .main {
  grid-area: main;
}
.evo-norway p {
  margin: 1em 0;
}
.evo-norway span.as-link {
  color: #d8005b;
  cursor: pointer;
}
.evo-norway form .form-group {
  color: #fff;
  border: 1px solid #2b2b2b;
  border-radius: 0.2em;
  background-color: #2b2b2b;
  padding: 1.5em 1em;
}
@media (max-width: 800px) {
  .evo-norway form .form-group {
    padding: 1em 1em;
  }
}
.evo-norway form .form-group .errorText {
  font-size: 0.8em;
  color: red;
  padding: 0 0.5em 1em 0.5em;
}
.evo-norway form .form-group h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 0.25em;
}
.evo-norway form .form-group h3 {
  color: #d8005b;
  font-size: 1.1em;
  font-weight: bold;
  padding-bottom: 0.25em;
  text-align: center;
}
.evo-norway form .form-group label[for=optIn], .evo-norway form .form-group label[for=optOut] {
  display: flex;
  padding-bottom: 1em;
  gap: 0.5em;
}
.evo-norway form .form-group input {
  font-size: 1em;
  color: #2b2b2b;
  font-family: "AvantGardeITC", "MS Trebuchet", sans-serif;
  margin: 0.5em 0;
  padding: 1em 0.5em;
  line-height: 1.5em;
  border: 2px solid #2b2b2b;
  border-radius: 0.5em;
  box-sizing: border-box;
}
.evo-norway form .form-group input[type=text], .evo-norway form .form-group input[type=email], .evo-norway form .form-group input[type=password] {
  width: 100%;
}
.evo-norway form .form-group input[type=text].code, .evo-norway form .form-group input[type=email].code, .evo-norway form .form-group input[type=password].code {
  width: 50%;
}
.evo-norway form .form-group input[type=radio] {
  appearance: none;
  background-color: none;
  margin: 0 0.3em 0 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid currentColor;
  border-radius: 50%;
  transform: translateY(0.2em);
  padding: 0.5em;
}
.evo-norway form .form-group input[type=radio]:checked {
  background: #d8005b;
}
.evo-norway form .form-group input[type=checkbox] {
  padding: 0.5em;
  background-color: red;
}
.evo-norway form .form-group input[type=checkbox]:checked {
  background: #d8005b;
}
.evo-norway form .form-group input:focus {
  outline: none;
  border: 2px solid #d8005b;
  font-weight: bold;
}
.evo-norway form .form-group input.error {
  border: 1px solid red;
}
.evo-norway form .form-group .gender, .evo-norway form .form-group .payment-method {
  display: flex;
}
@media (max-width: 800px) {
  .evo-norway form .form-group .gender, .evo-norway form .form-group .payment-method {
    display: block;
  }
}
.evo-norway form .form-group .gender label, .evo-norway form .form-group .payment-method label {
  padding-right: 0.5em;
}
.evo-norway form .form-group .gender .errorText {
  padding-top: 0.7em;
}
.evo-norway form .form-group .birthdate {
  margin-bottom: 1em;
}
.evo-norway form .form-group .birthdate label {
  display: block;
  padding: 0.1em 0;
}
.evo-norway form .form-group .mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.evo-norway form .form-group select {
  font-family: "AvantGardeITC", "MS Trebuchet", sans-serif;
  color: #2b2b2b;
  font-size: 1em;
  height: 3.6em;
  border: 2px solid #2b2b2b;
  border-radius: 0.5em;
  padding: 0.5em;
  box-sizing: border-box;
  margin-bottom: 0.3em;
}
.evo-norway form .form-group select:focus {
  outline: none;
  border: 2px solid #d8005b;
}
.evo-norway form .form-group select.locationSelect, .evo-norway form .form-group select.regionSelect {
  width: 100%;
}
.evo-norway form .form-group select.mobilePrefixSelect {
  margin-right: 0.5em;
}
.evo-norway form .form-group select.birthdateSelect {
  margin-right: 0.5em;
}
.evo-norway form .form-group select.error {
  border: 1px solid red;
}
.evo-norway form .form-group button.text-button {
  background-color: #2b2b2b;
  color: #d8005b;
  border: none;
  font-size: 1em;
  font-weight: bold;
}
.evo-norway form .form-group button.small {
  font-size: 0.9em;
  font-weight: normal;
}
.evo-norway form .form-group button.disabled {
  color: #fff;
}
.evo-norway form .form-group button:hover {
  cursor: pointer;
}
.evo-norway form .form-group div.subnote {
  text-align: right;
  position: relative;
  top: -0.8em;
}
.evo-norway form .form-group#verify-resend {
  padding: 1em 0.5em;
  text-align: center;
  border: none;
}
.evo-norway form footer {
  padding: 0 1em;
  color: #fff;
}
.evo-norway form footer label[for=acceptTerms], .evo-norway form footer label[for=optOut] {
  display: flex;
  padding-bottom: 0.4em;
  gap: 0.5em;
}
.evo-norway form footer input[type=checkbox] {
  appearance: none;
  background-color: transparent;
  margin: 0 0.1em 0 0;
  font: inherit;
  color: white;
  width: 0.75em;
  height: 0.25em;
  border: 1px solid currentColor;
  padding: 0.4em;
  position: relative;
}
.evo-norway form footer input[type=checkbox]:checked {
  background-color: #d8005b;
  color: white;
}
.evo-norway form footer input[type=checkbox]:checked::before {
  content: "";
  position: absolute;
  left: 0.4em;
  top: 0;
  width: 0.2em;
  height: 0.55em;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(0%, 15%) rotate(45deg);
}
.evo-norway form footer .errorText {
  font-size: 0.8em;
  color: red;
  padding: 0 0.5em 1em 0.5em;
}
.evo-norway form footer input[type=submit]#checkout-button {
  border-radius: 0.4em;
  font-size: 1em;
  text-transform: uppercase;
  line-height: 1.2em;
  height: 3em;
  margin: 1em 0;
}
.evo-norway form footer input[type=submit]#checkout-button .next-button {
  color: #fff;
  box-shadow: 0px 10px 20px rgba(216, 0, 91, 0.3);
  background: linear-gradient(127.18deg, #d8005b 0%, #d8005b 101.98%);
}
.evo-norway form footer input[type=submit]#checkout-button .off-button {
  color: gray !important;
  box-shadow: none;
  transform: none;
}
.evo-norway form footer input[type=submit]#checkout-button.next-button:active {
  box-shadow: 0px 4px 20px rgba(216, 0, 91, 0.3);
  transform: translateY(6px);
}
.evo-norway a {
  text-decoration: none;
  color: #d8005b;
}
.evo-norway a:hover {
  text-decoration: underline;
}
.evo-norway a:hover, .evo-norway a:visited, .evo-norway a:active {
  color: #d8005b;
}
.evo-norway .button-panel {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
}
.evo-norway .off-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;
  color: gray;
  box-shadow: none;
  background-color: #81093c;
}
.evo-norway .off-button:last-child {
  margin-left: auto;
}
.evo-norway .next-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;
  color: #fff;
  background-color: #d8005b;
  background: linear-gradient(to top, #d8005b, #d8005b);
}
.evo-norway .next-button:last-child {
  margin-left: auto;
}
.evo-norway a.next-button:focus, .evo-norway a.next-button:visited, .evo-norway a.next-button:active, .evo-norway a.back-button:focus, .evo-norway a.back-button:visited, .evo-norway a.back-button:active {
  color: #fff;
}
.evo-norway a.next-button:hover, .evo-norway a.back-button:hover {
  text-decoration: none;
}
.evo-norway #quote-presentation-mobile {
  display: none;
}
@media (max-width: 800px) {
  .evo-norway #quote-presentation-mobile {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    background: linear-gradient(145.4deg, #d8005b 0%, #d8005b 100%);
    display: block;
    grid-area: quote-presentation;
  }
  .evo-norway #quote-presentation-mobile p {
    margin: 0;
  }
}
.evo-norway .quote {
  margin-top: 2rem;
}
.evo-norway .quote .presentation {
  color: #fff;
  padding: 0.5rem 1rem 1rem 1rem;
  background: linear-gradient(145.4deg, #d8005b 0%, #d8005b 100%);
}
.evo-norway .quote .presentation p {
  margin: 0 0 1rem 0;
}
.evo-norway .quote .presentation h4 {
  font-weight: bold;
  margin: 0.5rem 0;
}
@media (max-width: 800px) {
  .evo-norway .quote .presentation {
    display: none;
  }
}
.evo-norway .quote .charges {
  margin: 1rem 0;
  padding: 0.5rem 1rem 1rem 1rem;
  color: #fff;
}
.evo-norway .quote .charges table {
  width: 100%;
  border-spacing: 0;
}
.evo-norway .quote .charges table caption {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.evo-norway .quote .charges table tbody td {
  font-size: 0.9em;
}
.evo-norway .quote .charges table tbody tr + tr td {
  padding-top: 0.4em;
  border-top: 1px solid silver;
}
.evo-norway .quote .charges table tbody td:last-child, .evo-norway .quote .charges table tfoot td:last-child {
  text-align: right;
}
.evo-norway .quote .charges table tfoot td:first-child {
  text-transform: uppercase;
}
.evo-norway .quote .charges table tfoot td {
  font-weight: bold;
  padding-top: 1em;
}
.evo-norway .quote .charges .next-payment {
  font-size: 0.6125em;
}
.evo-norway body .welcome {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "main" "picture";
  color: #fff;
  background: #2b2b2b;
}
@media (max-width: 800px) {
  .evo-norway body .welcome {
    margin-top: 1em;
  }
}
.evo-norway body .welcome[id=picture] {
  grid-area: picture;
  width: 100%;
}
.evo-norway body .welcome main {
  grid-area: main;
  padding: 10em 5em;
}
.evo-norway body .welcome main h1 {
  color: #fff;
  display: block;
  padding: 1rem 0;
  font-family: "AvantGardeITCBold";
}
@media (max-width: 800px) {
  .evo-norway body .welcome main h1 {
    text-align: center;
    margin: 0;
    padding: 1rem 0;
  }
}
.evo-norway body .welcome main h2 {
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 1rem 0;
  margin: 0;
}
.evo-norway body .welcome main h2 strong {
  color: #d8005b;
  font-style: normal;
  font-weight: bold;
}
.evo-norway body .welcome main p {
  padding: 1rem 0;
}
.evo-norway body .welcome main #sms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  border: 1px solid #d8005b;
}
.evo-norway body .welcome main #sms p {
  font-weight: bold;
  padding: 0;
}
.evo-norway body .welcome main a.next-button {
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  font-size: 1em;
  box-sizing: border-box;
  display: block;
  padding: 0.8em 0;
  height: 3em;
  border-radius: 2em;
}
@media (max-width: 800px) {
  .evo-norway body .welcome main {
    padding: 1em 2em;
  }
}
@media (max-width: 800px) {
  .evo-norway body .welcome {
    grid-template-columns: 1fr;
    grid-template-areas: "picture" "main";
  }
}
.evo-norway .welcome-footer {
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
}
.evo-norway .welcome-footer .next-button:last-child {
  margin-left: 1em;
}
.evo-norway .credit-card-container {
  font-family: "Open Sans", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 40em;
}
.evo-norway .credit-card-container .quote .presentation {
  display: none;
}
.evo-norway .credit-card-container .quote .charges {
  background: #fff;
  color: #2b2b2b;
  padding: 0 2em;
}

.evo-hp {
  @import url(//fonts.googleapis.com/css?family=Roboto:100,200,400,700,900);
  /*
   * Credit card form
   *
   * Note: cannot use flex layout because of Verifone iframe resizer (nothing displayed)
   */
}
.evo-hp html {
  box-sizing: border-box;
  font-size: 16px;
}
.evo-hp *, .evo-hp *:before, .evo-hp *:after {
  box-sizing: inherit;
}
.evo-hp body, .evo-hp h1, .evo-hp h2, .evo-hp h3, .evo-hp h4, .evo-hp h5, .evo-hp h6, .evo-hp p, .evo-hp ol, .evo-hp ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
.evo-hp ol, .evo-hp ul {
  list-style: none;
}
.evo-hp img {
  max-width: 100%;
  height: auto;
}
.evo-hp body {
  color: #2b2b2b;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: normal;
  font-weight: normal;
}
.evo-hp body {
  background: #fff;
  height: 100%;
}
.evo-hp body #root {
  height: 100%;
}
.evo-hp body .container {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
}
.evo-hp body .container .header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2em 0 1em 0;
  position: relative;
}
@media (max-width: 800px) {
  .evo-hp body .container .header {
    padding: 1.5em 0 0 0;
  }
}
.evo-hp body .container .header header.logo {
  width: 5rem;
  height: auto;
}
.evo-hp body .container .header .locale-switcher {
  position: absolute;
  top: 30%;
  right: 2em;
}
.evo-hp body .container .header .locale-switcher .locale {
  display: inline-block;
  margin-left: 0.5em;
}
.evo-hp body .container .header .locale-switcher .locale.active {
  font-weight: bold;
}
.evo-hp body .container h1 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  padding: 0 0 0.7em 0;
  font-family: "Roboto", sans-serif;
}
.evo-hp body .container .paymentError {
  background: red;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 1em 0;
}
.evo-hp body .container .signup-form-container {
  background: #2b2b2b;
  display: flex;
  justify-content: center;
}
.evo-hp body .container .signup-form-container form {
  max-width: 35rem;
  padding: 1em 5em;
}
@media (max-width: 800px) {
  .evo-hp body .container .signup-form-container form {
    color: #fff;
    grid-template-columns: 1fr;
    padding: 1em;
  }
}
.evo-hp body .container .signup-form-container form .form-group {
  margin-bottom: 1em;
}
.evo-hp body .container .content.processing, .evo-hp body .container .content.error {
  display: flex;
  flex-direction: column;
  padding: 5em 0 0 0;
  text-align: center;
}
.evo-hp body .container .content.processing img, .evo-hp body .container .content.error img {
  margin: 1em;
}
.evo-hp body .container .content .main {
  grid-area: main;
}
.evo-hp p {
  margin: 1em 0;
}
.evo-hp span.as-link {
  color: #01b2aa;
  cursor: pointer;
}
.evo-hp form .form-group {
  color: #fff;
  border: 1px solid #2b2b2b;
  border-radius: 0.2em;
  background-color: #2b2b2b;
  padding: 1.5em 1em;
}
@media (max-width: 800px) {
  .evo-hp form .form-group {
    padding: 1em 1em;
  }
}
.evo-hp form .form-group .errorText {
  font-size: 0.8em;
  color: red;
  padding: 0 0.5em 1em 0.5em;
}
.evo-hp form .form-group h2 {
  color: #fff;
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 0.25em;
}
.evo-hp form .form-group h3 {
  color: #01b2aa;
  font-size: 1.1em;
  font-weight: bold;
  padding-bottom: 0.25em;
  text-align: center;
}
.evo-hp form .form-group label[for=optIn], .evo-hp form .form-group label[for=optOut] {
  display: flex;
  padding-bottom: 1em;
  gap: 0.5em;
}
.evo-hp form .form-group input {
  font-size: 1em;
  color: #2b2b2b;
  font-family: "Roboto", sans-serif;
  margin: 0.5em 0;
  padding: 1em 0.5em;
  line-height: 1.5em;
  border: 2px solid #2b2b2b;
  border-radius: 0.5em;
  box-sizing: border-box;
}
.evo-hp form .form-group input[type=text], .evo-hp form .form-group input[type=email], .evo-hp form .form-group input[type=password] {
  width: 100%;
}
.evo-hp form .form-group input[type=text].code, .evo-hp form .form-group input[type=email].code, .evo-hp form .form-group input[type=password].code {
  width: 50%;
}
.evo-hp form .form-group input[type=radio] {
  appearance: none;
  background-color: none;
  margin: 0 0.3em 0 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 2px solid currentColor;
  border-radius: 50%;
  transform: translateY(0.2em);
  padding: 0.5em;
}
.evo-hp form .form-group input[type=radio]:checked {
  background: #01b2aa;
}
.evo-hp form .form-group input[type=checkbox] {
  padding: 0.5em;
  background-color: red;
}
.evo-hp form .form-group input[type=checkbox]:checked {
  background: #01b2aa;
}
.evo-hp form .form-group input:focus {
  outline: none;
  border: 2px solid #01b2aa;
  font-weight: bold;
}
.evo-hp form .form-group input.error {
  border: 1px solid red;
}
.evo-hp form .form-group .gender, .evo-hp form .form-group .payment-method {
  display: flex;
}
@media (max-width: 800px) {
  .evo-hp form .form-group .gender, .evo-hp form .form-group .payment-method {
    display: block;
  }
}
.evo-hp form .form-group .gender label, .evo-hp form .form-group .payment-method label {
  padding-right: 0.5em;
}
.evo-hp form .form-group .gender .errorText {
  padding-top: 0.7em;
}
.evo-hp form .form-group .birthdate {
  margin-bottom: 1em;
}
.evo-hp form .form-group .birthdate label {
  display: block;
  padding: 0.1em 0;
}
.evo-hp form .form-group .mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}
.evo-hp form .form-group select {
  font-family: "Roboto", sans-serif;
  color: #2b2b2b;
  font-size: 1em;
  height: 3.6em;
  border: 2px solid #2b2b2b;
  border-radius: 0.5em;
  padding: 0.5em;
  box-sizing: border-box;
  margin-bottom: 0.3em;
}
.evo-hp form .form-group select:focus {
  outline: none;
  border: 2px solid #01b2aa;
}
.evo-hp form .form-group select.locationSelect, .evo-hp form .form-group select.regionSelect {
  width: 100%;
}
.evo-hp form .form-group select.mobilePrefixSelect {
  margin-right: 0.5em;
}
.evo-hp form .form-group select.birthdateSelect {
  margin-right: 0.5em;
}
.evo-hp form .form-group select.error {
  border: 1px solid red;
}
.evo-hp form .form-group button.text-button {
  background-color: #2b2b2b;
  color: #01b2aa;
  border: none;
  font-size: 1em;
  font-weight: bold;
}
.evo-hp form .form-group button.small {
  font-size: 0.9em;
  font-weight: normal;
}
.evo-hp form .form-group button.disabled {
  color: #fff;
}
.evo-hp form .form-group button:hover {
  cursor: pointer;
}
.evo-hp form .form-group div.subnote {
  text-align: right;
  position: relative;
  top: -0.8em;
}
.evo-hp form .form-group#verify-resend {
  padding: 1em 0.5em;
  text-align: center;
  border: none;
}
.evo-hp form footer {
  padding: 0 1em;
  color: #fff;
}
.evo-hp form footer label[for=acceptTerms], .evo-hp form footer label[for=optOut] {
  display: flex;
  padding-bottom: 0.4em;
  gap: 0.5em;
}
.evo-hp form footer input[type=checkbox] {
  appearance: none;
  background-color: transparent;
  margin: 0 0.1em 0 0;
  font: inherit;
  color: white;
  width: 0.75em;
  height: 0.25em;
  border: 1px solid currentColor;
  padding: 0.4em;
  position: relative;
}
.evo-hp form footer input[type=checkbox]:checked {
  background-color: #01b2aa;
  color: white;
}
.evo-hp form footer input[type=checkbox]:checked::before {
  content: "";
  position: absolute;
  left: 0.4em;
  top: 0;
  width: 0.2em;
  height: 0.55em;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(0%, 15%) rotate(45deg);
}
.evo-hp form footer .errorText {
  font-size: 0.8em;
  color: red;
  padding: 0 0.5em 1em 0.5em;
}
.evo-hp form footer input[type=submit]#checkout-button {
  border-radius: 0.4em;
  font-size: 1em;
  text-transform: uppercase;
  line-height: 1.2em;
  height: 3em;
  margin: 1em 0;
}
.evo-hp form footer input[type=submit]#checkout-button .next-button {
  color: #fff;
  box-shadow: 0px 10px 20px rgba(1, 178, 170, 0.3);
  background: linear-gradient(127.18deg, #01b2aa 0%, #01b2aa 101.98%);
}
.evo-hp form footer input[type=submit]#checkout-button .off-button {
  color: gray !important;
  box-shadow: none;
  transform: none;
}
.evo-hp form footer input[type=submit]#checkout-button.next-button:active {
  box-shadow: 0px 4px 20px rgba(1, 178, 170, 0.3);
  transform: translateY(6px);
}
.evo-hp a {
  text-decoration: none;
  color: #01b2aa;
}
.evo-hp a:hover {
  text-decoration: underline;
}
.evo-hp a:hover, .evo-hp a:visited, .evo-hp a:active {
  color: #01b2aa;
}
.evo-hp .button-panel {
  grid-area: footer;
  display: flex;
  justify-content: space-between;
}
.evo-hp .off-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;
  color: gray;
  box-shadow: none;
  background-color: #036662;
}
.evo-hp .off-button:last-child {
  margin-left: auto;
}
.evo-hp .next-button {
  width: 100%;
  cursor: pointer;
  font-size: 0.8em;
  font-weight: bold;
  padding: 0.5em 1.5em;
  border: none;
  border-radius: 0.2em;
  color: #fff;
  background-color: #01b2aa;
  background: linear-gradient(to top, #01b2aa, #01b2aa);
}
.evo-hp .next-button:last-child {
  margin-left: auto;
}
.evo-hp a.next-button:focus, .evo-hp a.next-button:visited, .evo-hp a.next-button:active, .evo-hp a.back-button:focus, .evo-hp a.back-button:visited, .evo-hp a.back-button:active {
  color: #fff;
}
.evo-hp a.next-button:hover, .evo-hp a.back-button:hover {
  text-decoration: none;
}
.evo-hp #quote-presentation-mobile {
  display: none;
}
@media (max-width: 800px) {
  .evo-hp #quote-presentation-mobile {
    margin: 0 1rem;
    padding: 0.5rem 1rem;
    background: linear-gradient(145.4deg, #01b2aa 0%, #01b2aa 100%);
    display: block;
    grid-area: quote-presentation;
  }
  .evo-hp #quote-presentation-mobile p {
    margin: 0;
  }
}
.evo-hp .quote {
  margin-top: 2rem;
}
.evo-hp .quote .presentation {
  color: #fff;
  padding: 0.5rem 1rem 1rem 1rem;
  background: linear-gradient(145.4deg, #01b2aa 0%, #01b2aa 100%);
}
.evo-hp .quote .presentation p {
  margin: 0 0 1rem 0;
}
.evo-hp .quote .presentation h4 {
  font-weight: bold;
  margin: 0.5rem 0;
}
@media (max-width: 800px) {
  .evo-hp .quote .presentation {
    display: none;
  }
}
.evo-hp .quote .charges {
  margin: 1rem 0;
  padding: 0.5rem 1rem 1rem 1rem;
  color: #fff;
}
.evo-hp .quote .charges table {
  width: 100%;
  border-spacing: 0;
}
.evo-hp .quote .charges table caption {
  text-align: left;
  font-weight: bold;
  margin-bottom: 0.5em;
}
.evo-hp .quote .charges table tbody td {
  font-size: 0.9em;
}
.evo-hp .quote .charges table tbody tr + tr td {
  padding-top: 0.4em;
  border-top: 1px solid silver;
}
.evo-hp .quote .charges table tbody td:last-child, .evo-hp .quote .charges table tfoot td:last-child {
  text-align: right;
}
.evo-hp .quote .charges table tfoot td:first-child {
  text-transform: uppercase;
}
.evo-hp .quote .charges table tfoot td {
  font-weight: bold;
  padding-top: 1em;
}
.evo-hp .quote .charges .next-payment {
  font-size: 0.6125em;
}
.evo-hp body .welcome {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "main" "picture";
  color: #fff;
  background: #2b2b2b;
}
@media (max-width: 800px) {
  .evo-hp body .welcome {
    margin-top: 1em;
  }
}
.evo-hp body .welcome[id=picture] {
  grid-area: picture;
  width: 100%;
}
.evo-hp body .welcome main {
  grid-area: main;
  padding: 10em 5em;
}
.evo-hp body .welcome main h1 {
  color: #fff;
  display: block;
  padding: 1rem 0;
  font-family: "Roboto", sans-serif;
}
@media (max-width: 800px) {
  .evo-hp body .welcome main h1 {
    text-align: center;
    margin: 0;
    padding: 1rem 0;
  }
}
.evo-hp body .welcome main h2 {
  text-transform: uppercase;
  font-size: 1.2rem;
  padding: 1rem 0;
  margin: 0;
}
.evo-hp body .welcome main h2 strong {
  color: #01b2aa;
  font-style: normal;
  font-weight: bold;
}
.evo-hp body .welcome main p {
  padding: 1rem 0;
}
.evo-hp body .welcome main #sms {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;
  border: 1px solid #01b2aa;
}
.evo-hp body .welcome main #sms p {
  font-weight: bold;
  padding: 0;
}
.evo-hp body .welcome main a.next-button {
  text-align: center;
  vertical-align: center;
  font-weight: bold;
  font-size: 1em;
  box-sizing: border-box;
  display: block;
  padding: 0.8em 0;
  height: 3em;
  border-radius: 2em;
}
@media (max-width: 800px) {
  .evo-hp body .welcome main {
    padding: 1em 2em;
  }
}
@media (max-width: 800px) {
  .evo-hp body .welcome {
    grid-template-columns: 1fr;
    grid-template-areas: "picture" "main";
  }
}
.evo-hp .welcome-footer {
  grid-area: footer;
  display: flex;
  justify-content: flex-end;
}
.evo-hp .welcome-footer .next-button:last-child {
  margin-left: 1em;
}
.evo-hp .credit-card-container {
  font-family: "Roboto", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 40em;
}
.evo-hp .credit-card-container .quote .presentation {
  display: none;
}
.evo-hp .credit-card-container .quote .charges {
  background: #fff;
  color: #2b2b2b;
  padding: 0 2em;
}

html {
  height: 100%;
}

